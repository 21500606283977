import React, { useState, useRef } from "react"
import * as digitalDealerContactFormStyles from "./digital-dealer-contact-form.module.scss"
import { navigate } from "gatsby"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const DigitalDealerContactForm = ({ pageContext, location }) => {
  const [formState, setFormState] = useState({
    rockyBrandsAccountNumber: "",
    eCommerceWebsiteURL: "",
    eCommerceWebsiteContactFullName: "",
    eCommerceWebsiteContactEmailAddress: "",
    socialMediaContactFullName: "",
    socialMediaContactEmailAddress: "",
    digitalAssets: "",
    brands: "",
  })

  const [errors, setErrors] = useState({
    rockyBrandsAccountNumber: "",
    eCommerceWebsiteURL: "",
    eCommerceWebsiteContactFullName: "",
    eCommerceWebsiteContactEmailAddress: "",
    socialMediaContactFullName: "",
    socialMediaContactEmailAddress: "",
    digitalAssets: "",
    brands: "",
  })

  const handleChange = event => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    // Define validation functions
    const isRequired = value => (value ? "" : "This field is required.")
    const isValidEmail = value =>
      /\S+@\S+\.\S+/.test(value) ? "" : "Please provide a valid email address."
    const isValidUrl = value =>
      value === "" || /^https?:\/\/[^ ]+$/i.test(value)
        ? ""
        : "Please provide a valid URL."

    const fieldsToValidate = [
      { field: "rockyBrandsAccountNumber", validate: isRequired },
      { field: "eCommerceWebsiteURL", validate: [isRequired, isValidUrl] },
      { field: "eCommerceWebsiteContactFullName", validate: isRequired },
      { field: "eCommerceWebsiteContactEmailAddress", validate: isValidEmail },
      { field: "socialMediaContactFullName", validate: isRequired },
      { field: "socialMediaContactEmailAddress", validate: isValidEmail },
      { field: "digitalAssets", validate: isRequired },
    ]

    // Perform validation
    let newErrors = {}
    fieldsToValidate.forEach(({ field, validate }) => {
      const value = formState[field]
      const validators = Array.isArray(validate) ? validate : [validate]
      validators.forEach(validator => {
        const error = validator(value)
        if (error) {
          newErrors[field] = error
        }
      })
    })

    if (Object.keys(newErrors).length > 0) {
      // Update error state with new errors
      setErrors(newErrors)

      // Find the first field with an error and focus on it
      const firstErrorField = document.querySelector(
        `#${Object.keys(newErrors)[0]}`
      )
      if (firstErrorField) {
        firstErrorField.focus()
      }
    } else {
      // If no errors, perform form submission
      const form = event.target
      const formData = new FormData(form)
      fetch("/form-confirmation/", {
        method: "POST",
        body: formData,
      })
        .then(navigate("/form-confirmation/"))
        .catch(error => alert(error))
    }
  }

  const pageTitle = "Digital Dealer Contact Form"

  return (
    <Layout>
      <Seo pageTitle={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div className="inner-container-alt inner-y-padding">
        <div className={digitalDealerContactFormStyles.contentWrapper}>
          <h2>Digital Dealer Contact Form</h2>
          <h3 className="h5" data-typesense-field="description">
            Do you sell Rocky Brands products online and want collaboration or
            assets? Please fill out our form so a representative can be notified
            and reach out to you about your goals and needs.
          </h3>
          {/* form start */}
          <form
            data-netlify="true"
            name="digital-dealer-contact-form"
            method="POST"
            netlify-honeypot="special-request"
            onSubmit={handleSubmit}
          >
            <input class="hidden" name="special-request" />
            {/* hidden input for netlify */}
            <input
              type="hidden"
              name="form-name"
              value="digital-dealer-contact-form"
            ></input>
            <small>
              <span
                className="text-danger"
                style={{ fontSize: `1.1rem`, fontWeight: `bold` }}
              >
                *
              </span>{" "}
              indicates required field
            </small>
            {/* account number */}
            <div className="form-group">
              <label className="required" htmlFor="rockyBrandsAccountNumber">
                Rocky Brands Account Number
              </label>
              <input
                type="number"
                id="rockyBrandsAccountNumber"
                name="rockyBrandsAccountNumber"
                onChange={handleChange}
                value={formState.rockyBrandsAccountNumber}
                aria-describedby={
                  errors.rockyBrandsAccountNumber
                    ? "rockyBrandsAccountNumberError"
                    : null
                }
              />
              {errors.rockyBrandsAccountNumber && (
                <small
                  id="rockyBrandsAccountNumberError"
                  className="text-danger"
                >
                  {errors.rockyBrandsAccountNumber}
                </small>
              )}
            </div>
            {/* ecomm url */}
            <div className="form-group">
              <label className="required" htmlFor="eCommerceWebsiteURL">
                Ecommerce Website URL
              </label>
              <input
                type="text"
                id="eCommerceWebsiteURL"
                name="eCommerceWebsiteURL"
                onChange={handleChange}
                value={formState.eCommerceWebsiteURL}
                aria-describedby={
                  errors.eCommerceWebsiteURL ? "eCommerceWebsiteURLError" : null
                }
              />
              {errors.eCommerceWebsiteURL ? (
                <small id="eCommerceWebsiteURL-error" className="text-danger">
                  {errors.eCommerceWebsiteURL} (ie. https://www.rockybrands.com)
                </small>
              ) : (
                <small>ie. https://www.rockybrands.com</small>
              )}
            </div>
            {/* ecomm name */}
            <div className="form-group">
              <label
                className="required"
                htmlFor="eCommerceWebsiteContactFullName"
              >
                Ecommerce Website Contact Full Name
              </label>
              <input
                type="text"
                id="eCommerceWebsiteContactFullName"
                name="eCommerceWebsiteContactFullName"
                onChange={handleChange}
                value={formState.eCommerceWebsiteContactFullName}
                aria-describedby={
                  errors.eCommerceWebsiteContactFullName
                    ? "eCommerceWebsiteContactFullNameError"
                    : null
                }
              />
              {errors.eCommerceWebsiteContactFullName && (
                <small
                  id="eCommerceWebsiteContactFullNameError"
                  className="text-danger"
                >
                  {errors.eCommerceWebsiteContactFullName}
                </small>
              )}
            </div>
            {/* ecomm email */}
            <div className="form-group">
              <label
                className="required"
                htmlFor="eCommerceWebsiteContactEmailAddress"
              >
                Ecommerce/Website Contact Email Address
              </label>
              <input
                type="text"
                id="eCommerceWebsiteContactEmailAddress"
                name="eCommerceWebsiteContactEmailAddress"
                onChange={handleChange}
                value={formState.eCommerceWebsiteContactEmailAddress}
                aria-describedby={
                  errors.eCommerceWebsiteContactEmailAddress
                    ? "eCommerceWebsiteContactEmailAddressError"
                    : null
                }
              />
              {errors.eCommerceWebsiteContactEmailAddress ? (
                <small
                  id="eCommerceWebsiteContactEmailAddress-error"
                  className="text-danger"
                >
                  {errors.eCommerceWebsiteContactEmailAddress} (i.e.
                  email@domain.com)
                </small>
              ) : (
                <small>i.e. email@domain.com</small>
              )}
            </div>
            {/* social media */}
            <div className="form-group">
              <label className="required" htmlFor="socialMediaContactFullName">
                Social Media Contact Full Name
              </label>
              <input
                type="text"
                id="socialMediaContactFullName"
                name="socialMediaContactFullName"
                onChange={handleChange}
                value={formState.socialMediaContactFullName}
                aria-describedby={
                  errors.socialMediaContactFullName
                    ? "socialMediaContactFullNameError"
                    : null
                }
              />
              {errors.socialMediaContactFullName && (
                <small
                  id="socialMediaContactFullNameError"
                  className="text-danger"
                >
                  {errors.socialMediaContactFullName}
                </small>
              )}
            </div>
            {/* social email */}
            <div className="form-group">
              <label
                className="required"
                htmlFor="socialMediaContactEmailAddress"
              >
                Social Media Contact Email Address
              </label>
              <input
                type="text"
                id="socialMediaContactEmailAddress"
                name="socialMediaContactEmailAddress"
                onChange={handleChange}
                value={formState.socialMediaContactEmailAddress}
                aria-describedby={
                  errors.socialMediaContactEmailAddress
                    ? "socialMediaContactEmailAddressError"
                    : null
                }
              />
              {errors.socialMediaContactEmailAddress ? (
                <small
                  id="socialMediaContactEmailAddress-error"
                  className="text-danger"
                >
                  {errors.socialMediaContactEmailAddress} (i.e.
                  email@domain.com)
                </small>
              ) : (
                <small>i.e. email@domain.com</small>
              )}
            </div>
            {/* radio, digital assets  */}
            <fieldset className="form-group" style={{ marginTop: `2rem` }}>
              <legend className="required" htmlFor="digitalAssets">
                <strong>
                  Do you need digital assets such as graphics, lifestyle imagery
                  and logos?
                </strong>
              </legend>
              <div className="form-group">
                <div>
                  <label className="radio-alt">
                    <input
                      type="radio"
                      id="digitalAssets-yes"
                      name="digitalAssets"
                      value="yes"
                      onChange={handleChange}
                      aria-describedby={
                        errors.digitalAssets ? "digitalAssetsError" : null
                      }
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div>
                  <label className="radio-alt">
                    <input
                      type="radio"
                      id="digitalAssets-no"
                      name="digitalAssets"
                      value="no"
                      onChange={handleChange}
                      aria-describedby={
                        errors.digitalAssets ? "digitalAssetsError" : null
                      }
                    />
                    <span>No</span>
                  </label>
                </div>
              </div>
              {errors.digitalAssets && (
                <small id="digitalAssetsError" className="text-danger">
                  {errors.digitalAssets}
                </small>
              )}
            </fieldset>
            {/* end radio, digital assets? */}
            {/* checkbox, Which of these brands do you sell or promote online?  */}
            <fieldset
              className="form-group"
              style={{ marginTop: `2rem` }}
              name="digital-dealer-brands-sold-or-promoted"
            >
              <legend htmlFor="digital-dealer-brands-sold-or-promoted-online">
                <strong>
                  Which of these brands do you sell or promote online?
                </strong>
              </legend>
              <div className="form-group">
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-rocky-boots"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="rocky-boots"
                    />
                    <span>Rocky Boots</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-georgia-boot"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="georgia-boot"
                    />
                    <span>Georgia Boot</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-durango-boots"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="durango-boots"
                    />
                    <span>Durango Boots</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-the-original-muck-boot-company"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="the-original-muck-boot-company"
                    />
                    <span>The Original Muck Boot Company</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-xtratuf"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="xtratuf"
                    />
                    <span>XTRATUF</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-ranger"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="ranger"
                    />
                    <span>Ranger</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-slipgrips"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="slipgrips"
                    />
                    <span>SlipGrips</span>
                  </label>
                </div>
                <div>
                  <label className="checkbox-alt">
                    <input
                      type="checkbox"
                      id="digital-dealer-brands-sold-or-promoted-online-lehigh-safety-shoes"
                      name="digital-dealer-brands-sold-or-promoted-online"
                      value="lehigh-safety-shoes"
                    />
                    <span>Lehigh Safety Shoes</span>
                  </label>
                </div>
              </div>
            </fieldset>
            {/* submit button */}
            <div>
              <input className="CTAblack" type="submit" value="Submit" />
            </div>
            {/* end submit button */}
          </form>
        </div>
      </div>
    </Layout>
  )
}

export default DigitalDealerContactForm

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Digital Dealer Contact Form</title>
    <meta
      name="description"
      content="Do you sell Rocky Brands products online and want collaboration or
      assets? Please fill out our form so a representative can be notified
      and reach out to you about your goals and needs."
    />
  </>
)
